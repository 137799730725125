// @flow
import * as React from 'react';

import { useReactiveVar } from '@apollo/client';
import Field from '../../../basic-components/field';
import Select from '../../../basic-components/select';
import StatementOption from './option';
import { gpsrConfigsVar } from '../../../utils/apollo-cache';
import { getOptions } from '../../../store/gpsr';
import t from '../locale';
import cs from './styles.pcss';

type Props = {
  label: React.Node,
  values: string[],
  fieldRef?: React.Ref<any>,
  noGrid?: boolean,
  onChanged: (value: any) => void,
  disabled?: boolean,
  clearStatements: () => void,
};

export default function StatementsMultiSelectField({
  label,
  values,
  fieldRef,
  noGrid,
  onChanged,
  disabled,
  clearStatements,
}: Props): React.Node {
  const refInput = React.useRef();
  const handleLabelClick: () => void = () => {
    if (refInput && refInput.current) {
      refInput.current.focus();
    }
  };
  const maxSelections = 8;
  const disableCheckbox = values.length >= maxSelections;

  const sts = getOptions(useReactiveVar(gpsrConfigsVar), 'statements');

  const statements =
    sts.map((v) => ({
      ...v,
      checked: values?.includes(v.value),
      disableCheckbox: !values.includes(v.value) && disableCheckbox
    })) || [];

  const additionalValue =
    values && values.length > 1 ? `(+${values.length - 1})` : '';
  const firstLabel = statements.find((v) => v.value === values[0])?.label || '';
  const keyword =
    values && values.length > 0 ? firstLabel + additionalValue : '';

    return (
    <Field
      innerRef={fieldRef}
      label={label}
      onLabelClick={handleLabelClick}
      noGrid={noGrid}
    >
      <Select
        searchable={false}
        displayValue={keyword}
        innerRef={refInput}
        value={1}
        options={statements}
        onChange={(option) => {
         if(!disableCheckbox || values.some(item => item === option?.value)){
            onChanged(option?.value);
          }
        }}
        renderOption={(option) => (
          <StatementOption
            label={option.label}
            value={option.value}
            checked={option.checked}
            disableCheckbox={option.disableCheckbox}
          />
        )}
        onBlur={() => {}}
        multiline
        disabled={disabled}
        disableToggleDown
      />
      <button 
      className={cs.button}
      onClick={() => clearStatements()}>
        <div>{t('button.clear')}</div>
      </button>
    </Field>
  );
}
