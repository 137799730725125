// @flow
import * as React from 'react';
import cx from 'classnames';

import Checkbox from '../checkbox';

import cs from './styles.pcss';

type Props = {
  id: string,
  url: string,
  onRemove?: (index: number) => void,
  innerRef?: React.Ref<any>,
  dragged: boolean,
  style?: any,
  description: string,
  checked: boolean,
  onChanged: (e: any) => void,
  disabled: boolean
};

export default function DraggableProgramsItem({
  id,
  url,
  dragged,
  style,
  innerRef,
  description,
  checked,
  onChanged,
  disabled,
  ...props
}: Props): React.Node {
  return (
    // $FlowFixMe
    <div
      ref={innerRef}
      className={cx(cs.image, {
        [cs.dragged]: dragged,
        [cs.mainImage]: true,
      })}
      style={{
        display: 'flex',
        flexDirection: 'column'
      }}
      {...props}
    >
      <Checkbox
        key={`${id}.cost.free`}
        name={`${id}.cost.free`}
        checked={checked}
        disabled={disabled}
        onChange={(e) => {
          onChanged(e);
        }}
      />
      <div className={cs.imageDiv}>
        <img src={url} style={{ width: '100%', height: '100%' }}></img>
      </div>
      <div style={{ textAlign: 'center', fontSize: '15px' }}>{description}</div>
    </div>
  );
}
