// @flow
import * as React from 'react';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import createFocusOnError from 'final-form-focus';
import classNames from 'classnames/bind';
import { isNil } from 'ramda';

import { useReactiveVar } from '@apollo/client';

import { FormField } from '../../../hocs/final-form';
import useMediaQuery from '../../../hooks/use-media-query';

import ApolloLightbox from '../../apollo-lightbox';
import Button from '../../../basic-components/button';
import SelectField from '../../../basic-components/select-field';
import FieldSet from '../../field-set';
import FieldSetTitle from '../../field-set-title';
import ThreeColumnsLayout from '../../../basic-components/three-columns-layout';

import t from '../locale';
import cs from '../styles.pcss';

import NewMultiSelectField from '../../../basic-components/multi-select-field/new_index';
import Link from '../../../basic-components/link';
import settings from '../../../settings';
import FieldSetDescription from '../../field-set-description';
import { gpsrConfigsVar } from '../../../utils/apollo-cache';
import type { GpsrDocument } from '../../../store/gpsr';
import { getOptions } from '../../../store/gpsr';
import UploadFileField from '../../../basic-components/upload-file-field';
import { fetchFile } from '../../../utils/http';
import Icon from '../../../basic-components/icon';

const cx = classNames.bind(cs);

type Props = {
  documents: any,
  selectedIndex: number,
  onChange: (value: any) => void,
};

const focusOnErrors = createFocusOnError();
const renderFooter =
  (
    handleSubmit?: any,
    submitError?: boolean,
    submitting?: boolean,
    mediumScreen: boolean = true
  ) =>
  (closeOverlay: () => Promise<void>) =>
    (
      <ThreeColumnsLayout
        left={
          <Button
            onClick={closeOverlay}
            mode="secondary"
            size={mediumScreen ? 'medium' : 'inModal'}
          >
            {t('buttons.close')}
          </Button>
        }
        center={
          <Button
            loading={submitting}
            onClick={handleSubmit}
            size={mediumScreen ? 'medium' : 'inModal'}
          >
            {t('buttons.save')}
          </Button>
        }
        className={cs.footerWrapper}
      />
    );

function GpsrDocumentLightbox({
  documents,
  selectedIndex,
  onChange,
}: Props): React.Node {
  const veryLargeScreen = useMediaQuery(`(min-width: 1000px)`);
  const mediumScreen = useMediaQuery(`(min-width: 600px)`);
  const document =
    selectedIndex === -1 || !documents[selectedIndex]
      ? {}
      : documents[selectedIndex];

  const [languages, setLanguages] = React.useState([]);
  const [documentFile, setDocumentFile] = React.useState(null);
  const [documentType, setDocumentType] = React.useState(null);
  const [maxFileSize, setMaxFileSize] = React.useState(false);
  const [documentFileName, setDocumentFileName] = React.useState(null);

  React.useEffect(() => {
    setDocumentFile(document.documentFile);
    setLanguages(document.languages || []);
    setDocumentType(document.documentType);
    setDocumentFileName(document.documentFileName);
    setMaxFileSize(false);
  }, [documents, selectedIndex]);

  const allLanguages = getOptions(useReactiveVar(gpsrConfigsVar), 'languages');
  const documentTypes = getOptions(
    useReactiveVar(gpsrConfigsVar),
    'documentTypes'
  );
  if (!documentTypes) return null;

  const ACCEPTED_UPLOAD_FORMATS = [
    'application/pdf',
    'image/jpeg',
    'image/png',
    'image/jpg',
  ];

  const handleAddFile = (file) => {
    setMaxFileSize(false);
    setDocumentFileName(file ? file.name : null);
    const { action, method } = settings.form.gpsrUpload;

    const body = new window.FormData();
    body.append('file', file);

    fetchFile(action, { method, body }).then(({ ok, data }) => {
      if (ok) {
        setDocumentFile(data.url);
      }
      Promise.resolve(data.url);
    });
  };

  const handleSetContent = (files: File[] | FileList): void => {
    if (files[0]) {
      if (files[0].size > 9.5 * 1024 * 1024) {
        setMaxFileSize(true);
      } else {
        handleAddFile(files[0]);
      }
    }
  };

  return (
    <Form
      initialValues={{}}
      initialValuesEqual={() => false}
      subscription={{
        submitting: true,
        submitError: true,
      }} // rerender form component only when submitting is changed
      mutators={{ ...arrayMutators }}
      decorators={[focusOnErrors]}
      onSubmit={(data) => {
        onChange({
          documentType,
          languages,
          documentFile,
          documentFileName,
        });
      }}
    >
      {({ handleSubmit, submitError, submitting }) => (
        <ApolloLightbox
          id="GpsrDocument"
          header={t('document.title')}
          footer={renderFooter(
            handleSubmit,
            submitError,
            submitting,
            mediumScreen
          )}
        >
          <form className={cx(cs.wrapper)} onSubmit={(e) => e.preventDefault()}>
            <FieldSet>
              <FieldSetDescription>
                {t('document.formDescription')}
                <Link
                  target="_blank"
                  href={settings.references.gpsrLinks('safety')}
                  className={cs.link}
                  noUnderline={false}
                  onClick={() => {
                    // ANALYTICS.goToHelpCenter(location);
                  }}
                >
                  {t('linkText')}
                </Link>
              </FieldSetDescription>
              <FormField
                name="documentType"
                validate={() =>
                  isNil(documentType)
                    ? t('document.fields.documentType.validate.empty')
                    : null
                }
                options={documentTypes}
                Component={SelectField}
                label={t('document.fields.documentType.label')}
                noGrid={!veryLargeScreen}
                onChange={({ value }) => {
                  setDocumentType(value);
                }}
                searchable={false}
                useDefaultValue
                value={documentType}
              />
              <FormField
                name="languages"
                validate={() =>
                  isNil(languages) || languages.length === 0
                    ? t('document.fields.languages.validate.empty')
                    : null
                }
                Component={NewMultiSelectField}
                options={allLanguages}
                label={t('document.fields.languages.label')}
                noGrid={!veryLargeScreen}
                onChange={(selectedValues) => {
                  setLanguages(selectedValues);
                  return selectedValues;
                }}
                searchable={false}
                values={languages}
                disableCustomOptions
                useDefaultValue
              />

              <FormField
                name="uploadFileField"
                validate={() =>
                  isNil(documentFile)
                    ? t('document.fields.documentFile.validate.empty')
                    : null
                }
                Component={UploadFileField}
                label={t('document.fields.documentFile.label')}
                noGrid={!veryLargeScreen}
                onChange={handleSetContent}
                onDelete={() => {
                  setDocumentFile(null);
                }}
                uploads={[]}
                accept={ACCEPTED_UPLOAD_FORMATS}
                hideCounter
              />
              <FieldSetTitle
                leftColumn=" "
                className={
                  documentFile && !documentFile?.endsWith('pdf')
                    ? ''
                    : cs.hidden
                }
              >
                {documentFileName ? (
                  <div>
                    <Link href={documentFile || ''} target="_blank" area="">
                      <Icon name="attach" size={16} />
                      {documentFileName}
                    </Link>
                  </div>
                ) : (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <></>
                )}
                <img
                  src={documentFile}
                  className={[cs.mixImg]}
                  alt={documentFileName}
                />
              </FieldSetTitle>
              <FieldSetTitle
                leftColumn=" "
                className={
                  documentFile && documentFile?.endsWith('pdf') ? '' : cs.hidden
                }
              >
                <Link href={documentFile || ''} target="_blank">
                  <Icon name="attach" size={16} />
                  {documentFileName || 'pdf'}
                </Link>
              </FieldSetTitle>
              <FieldSetTitle leftColumn=" ">
                <div className={maxFileSize ? cs.errorTip : cs.tip}>
                  {t('document.fields.documentFile.tip')}
                </div>
              </FieldSetTitle>
            </FieldSet>
          </form>
        </ApolloLightbox>
      )}
    </Form>
  );
}

export default GpsrDocumentLightbox;
