// @flow
import * as React from 'react';
import classNames from 'classnames/bind';
import NumberFormat from 'react-number-format';

import useCombinedRefs from '../../hooks/use-combined-refs';

import cs from './styles.pcss';

const cx = classNames.bind(cs);

type MaskT = 'number' | 'float' | 'weight' | 'price';

export type Props = {
  innerRef?: React.Ref<any>,
  value: string,
  size?: 'small' | 'medium',
  className?: string,
  inputClassName?: string,
  maxLength?: number,
  icon?: React.Node,
  disabled?: boolean,
  onFocus?: (event: SyntheticInputEvent<HTMLInputElement>) => void,
  onBlur?: (event: SyntheticInputEvent<HTMLInputElement>) => void,
  onChange: (event: SyntheticInputEvent<HTMLInputElement>) => void,
  onEnterPress?: (event: SyntheticKeyboardEvent<HTMLInputElement>) => void, 
  error?: boolean | null,
  iconClassName?: string,
  mask?: MaskT,
  allowOverLimit?: boolean,
  allowNegative?: boolean,
};

const WEIGHT_MAX_VALUE = 999;

const getDecimalScale = (mask: MaskT) => {
  switch (mask) {
    case 'number':
      return 0;
    case 'weight':
      return 3;
    case 'price':
      return 2;
    case 'float':
    default:
      return 9;
  }
};

export default function Input({
  value = '',
  icon = null,
  size = 'medium',
  className,
  inputClassName,
  maxLength,
  error,
  innerRef,
  iconClassName,
  mask,
  allowOverLimit,
  allowNegative = false,
  onEnterPress,
  ...props
}: Props): React.Node {
  const [focused, setFocused] = React.useState(false);
  const inputRef = React.useRef();
  const combinedRef = useCombinedRefs(innerRef, inputRef);

  const getAllowedValue = () => {
    if (allowOverLimit) {
      return value;
    }

    return maxLength ? value.substring(0, maxLength) : value;
  };

  const handleChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    if (
      maxLength &&
      !allowOverLimit &&
      e.currentTarget.value.length > maxLength
    ) {
      return;
    }

    props.onChange(e);
  };

  const handleFocus = (e: SyntheticInputEvent<HTMLInputElement>) => {
    setFocused(true);
    if (props.onFocus) {
      props.onFocus(e);
    }
  };

  const handleBlur = (e: SyntheticInputEvent<HTMLInputElement>) => {
    setFocused(false);
    if (props.onBlur) {
      props.onBlur(e);
    }
  };

  const handleKeyDown = (e: SyntheticKeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && onEnterPress) {
      onEnterPress(e);
    }
  };

  const renderCounter = () => {
    if (!maxLength) return null;

    const commonMaxLenght = maxLength || 0;

    const current =
      value.length > commonMaxLenght && !allowOverLimit
        ? commonMaxLenght
        : value.length;

    return (
      <div className={cx('counter', { full: current >= commonMaxLenght })}>
        {`${current}/${commonMaxLenght}`}
      </div>
    );
  };

  const renderIcon = () => {
    if (!icon) return null;

    return <div className={cx('icon', iconClassName)}>{icon}</div>;
  };

  const { disabled } = props;

  const actualProps = {
    ...props,
    value: getAllowedValue(),
    onChange: handleChange,
    onFocus: handleFocus,
    onBlur: handleBlur,
    onKeyDown: handleKeyDown,
    className: cx('input', { withIcon: Boolean(icon) }, inputClassName),
  };

  const wrapperClassName = cx(
    'wrapper',
    { focused, error, disabled },
    size,
    className
  );

  return (
    <div className={wrapperClassName}>
      {mask ? (
        <NumberFormat
          {...actualProps}
          getInputRef={combinedRef}
          allowNegative={allowNegative}
          isNumericString
          allowedDecimalSeparators={[',', '.']}
          decimalScale={getDecimalScale(mask)}
          isAllowed={({ value: numVal }) =>
            mask !== 'weight' || numVal <= WEIGHT_MAX_VALUE
          }
          fixedDecimalScale={mask === 'price'}
        />
      ) : (
        <input {...actualProps} ref={innerRef} />
      )}
      {renderCounter()}
      {renderIcon()}
    </div>
  );
}
